import cn from 'classnames'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import React, { useEffect, useState } from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { Squash as Hamburger } from 'hamburger-react'
import { AnimatePresence, motion } from 'framer-motion'
import { Link } from '@/components/elements/Link'
import { Logo } from '@/components/elements/Logo'
import { OmniumLogo } from '@/components/elements/OmniumLogo'
import { Divider } from './Divider'

export interface Page {
  title: string
  url: string
}

export interface HeaderProps {
  pages: Page[]
}

const OmniumLogoWithLink = () => (
  <a
    href="https://www.omnium.cat"
    target="_blank"
    rel="noreferrer"
    className="text-navy-900 hover:text-omnium transition-all duration-300"
  >
    <span className="sr-only">Òmnium Cultural</span>
    <OmniumLogo />
  </a>
)

function DesktopMenu({ pages }: HeaderProps) {
  return (
    <div className="hidden flex-1 items-center justify-end font-semibold space-x-10 lg:flex">
      <ul className="flex space-x-10">
        {pages.map((page) => (
          <li key={page.title}>
            <Link variant="secondary" href={page.url}>
              {page.title}
            </Link>
          </li>
        ))}
      </ul>
      <OmniumLogoWithLink />
    </div>
  )
}

type MobileMenuProps = HeaderProps & {
  onOpenMenu: () => void
  onCloseMenu: () => void
  showMenu: boolean
}

function MobileMenu({ onOpenMenu, onCloseMenu, showMenu }: MobileMenuProps) {
  const router = useRouter()

  useEffect(() => {
    document.body.style.overflowY = showMenu ? 'hidden' : 'unset'
  }, [showMenu])

  useEffect(() => {
    if (showMenu) {
      onCloseMenu()
    }
  }, [router.asPath])
  return (
    <div className="flex flex-1 items-center justify-end font-semibold lg:hidden">
      <Hamburger
        label="Obre menú"
        toggled={showMenu}
        size={22}
        toggle={showMenu ? onCloseMenu : onOpenMenu}
      />
    </div>
  )
}

function MobileSubMenu({ pages, show }: HeaderProps & { show: boolean }) {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="fixed z-30 inset-0 flex flex-col items-center justify-center bg-beige lg:hidden"
          key="mobile-menu"
          initial={{ y: '-100%' }}
          animate={{ y: 0 }}
          exit={{ y: '-100%' }}
          transition={{
            type: 'spring',
            duration: 0.5,
          }}
        >
          <div className="mt-22 w-full overflow-auto">
            <div className="flex flex-1 flex-col items-center justify-center py-4">
              <ul>
                {pages.map((page) => (
                  <li
                    key={page.title}
                    className="flex items-center justify-center h-20"
                  >
                    <NextLink href={page.url}>
                      <a className="flex items-center justify-center text-navy-900 font-display text-2xl font-black border-b-2 border-navy-900 border-opacity-0 hover:border-opacity-100 transition-all duration-300">
                        <span>{page.title}</span>
                      </a>
                    </NextLink>
                  </li>
                ))}
              </ul>
              <div className="flex flex-shrink-0 items-center h-20">
                <Divider />
              </div>
              <div className="flex-shrink-0 my-4">
                <OmniumLogoWithLink />
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export function Header(props: HeaderProps): JSX.Element {
  const [showShadow, setShowShadow] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  const { pages } = props

  useScrollPosition(({ currPos }) => {
    if (currPos.y < 0) {
      setShowShadow(true)
    } else {
      setShowShadow(false)
    }
  })

  return (
    <>
      <div className="h-22" />
      <nav
        className={cn(
          'bg-white transition-all fixed w-full z-50 top-0 left-0',
          {
            'shadow-100': showShadow,
          }
        )}
      >
        <div className="flex items-center mx-auto px-6 max-w-7xl h-22">
          <div>
            <motion.div whileHover={{ rotate: '-3deg' }}>
              <NextLink href="/">
                <a className="cursor-pointer">
                  <span className="sr-only">Embolica&apos;t</span>
                  <Logo />
                </a>
              </NextLink>
            </motion.div>
          </div>
          <DesktopMenu {...props} />
          <MobileMenu
            {...props}
            onOpenMenu={() => setShowMobileMenu(true)}
            onCloseMenu={() => setShowMobileMenu(false)}
            showMenu={showMobileMenu}
          />
        </div>
      </nav>
      <MobileSubMenu show={showMobileMenu} pages={pages} />
    </>
  )
}
