import { UrlObject } from 'url'
import { ArrowForwardOutline } from 'react-ionicons'
import { FC, SVGProps } from 'react'
import { MailIcon, PhoneIcon } from '@heroicons/react/outline'
import { Logo } from '@/components/elements/Logo'
import { Container } from '@/components/utils'
import { Link } from '@/components/elements/Link'
import { IonIcon } from '@/src/icons'
import {
  Facebook,
  Instagram,
  Telegram,
  Twitter,
  Youtube,
} from '@/components/svg/socialMediaIcons'
import { AddressObject } from '../ContactSidebar'

export interface FooterLink {
  title: string
  url: string | UrlObject
}

function SocialMediaLink({
  icon,
  url,
  network,
  target = null,
}: {
  url: string
  network: string
  icon: FC<SVGProps<SVGSVGElement>>
  target?: '_blank' | '_self' | '_parent' | '_top' | null
}) {
  const Icon = icon

  return (
    <Link
      href={url}
      label={network}
      icon={<Icon className="w-6 h-6" />}
      variant="secondary"
      {...(target !== null && { target })}
    />
  )
}

export interface FooterProps {
  links: FooterLink[]
  instagramUrl: string
  facebookUrl: string
  twitterUrl: string
  telegramUrl: string
  youtubeUrl: string
  phone: string
  email: string
  address: AddressObject
  copyright: string
  signupUrl: string
  signupText: string
}
export function Footer({
  links,
  phone,
  instagramUrl,
  twitterUrl,
  facebookUrl,
  telegramUrl,
  youtubeUrl,
  email,
  address,
  copyright,
  signupUrl,
  signupText,
}: FooterProps) {
  const addressInLine = `${address.headquarters} ${address.street} ${address.city}`
  return (
    <footer className="py-14 text-navy-900 bg-white">
      <Container className="flex flex-col items-center">
        <Logo />
        <ul className="flex flex-col flex-wrap items-center justify-center mt-8 font-semibold md:flex-row">
          {links.map(({ title, url }) => (
            <li key={title} className="px-8 py-2">
              <Link variant="secondary" href={url}>
                {title}
              </Link>
            </li>
          ))}

          <li className="px-8 py-2">
            <Link
              href={signupUrl}
              icon={<IonIcon icon={ArrowForwardOutline} />}
              animateIcon
              target="_blank"
            >
              {signupText}
            </Link>
          </li>
        </ul>
        <div className="grid gap-6 grid-flow-col mt-8">
          <SocialMediaLink
            icon={Instagram}
            url={instagramUrl}
            network="Instagram"
            target="_blank"
          />
          <SocialMediaLink
            icon={Facebook}
            url={facebookUrl}
            network="Facebook"
            target="_blank"
          />
          <SocialMediaLink
            icon={Twitter}
            url={twitterUrl}
            network="Twitter"
            target="_blank"
          />
          <SocialMediaLink
            icon={Telegram}
            url={telegramUrl}
            network="Telegram"
            target="_blank"
          />
          <SocialMediaLink
            icon={Youtube}
            url={youtubeUrl}
            network="Youtube"
            target="_blank"
          />
        </div>
        <div className="flex flex-wrap items-center justify-center mt-8 text-sm">
          <div className="inline-flex items-center mx-3 sm:mt-2">
            <PhoneIcon className="mr-2 w-5 h-5" />
            <Link
              className="py-4 sm:py-0"
              variant="secondary"
              target="_blank"
              href={`tel:${email}`}
            >
              <span>{phone}</span>
            </Link>
          </div>
          <div className="inline-flex items-center mx-3 sm:mt-2">
            <MailIcon className="mr-2 w-5 h-5" />
            <Link
              className="py-4 sm:py-0"
              variant="secondary"
              target="_blank"
              href={`mailto:${email}`}
            >
              {email}
            </Link>
          </div>
          <address className="inline-block mt-2 mx-3 text-center not-italic">
            {addressInLine}
          </address>
        </div>
        <div className="mt-8 text-center text-sm">{copyright}</div>
      </Container>
    </footer>
  )
}
