import cn from 'classnames'

export const Container: React.FC<{ className?: string; narrow?: boolean }> = ({
  className = '',
  children,
  narrow = false,
}) => (
  <div
    className={cn(
      `mx-auto px-5 md:px-5`,
      narrow ? 'max-w-2xl' : 'max-w-7xl',
      className
    )}
  >
    {children}
  </div>
)
