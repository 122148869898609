export function Logo() {
  return (
    <svg
      className="w-4/5 sm:w-auto"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="308"
      height="40"
      viewBox="0 0 311 40"
    >
      <g clipPath="url(#a)">
        <path
          opacity=".95"
          d="M146.123 20a8.98 8.98 0 0 1 8.041-8.938V0H0v11.014a8.987 8.987 0 0 1 0 17.972V40h154.164V28.938A8.987 8.987 0 0 1 146.123 20ZM301.397 20a8.987 8.987 0 0 1 8.994-8.986V0H156.226v11.089a8.982 8.982 0 0 1 7.819 8.904 8.983 8.983 0 0 1-7.819 8.904v11.09h154.165v-11a8.994 8.994 0 0 1-6.359-2.63A8.99 8.99 0 0 1 301.397 20Z"
          fill="#231F20"
        />
        <g opacity=".95" fill="#fff">
          <path
            opacity=".95"
            d="M15.356 15.308V9.233h16.082v6.075h-4.185v15.473h-7.459V15.308h-4.438ZM31.596 30.78l4.76-21.547h11.288l4.849 21.548h-7.534l-.61-2.48h-4.87l-.513 2.48h-7.37Zm4.89-27.307h7.788l1.096 4.376h-5.535l-3.349-4.376Zm5.144 11.61-1.22 7.533h3.179l-1.158-7.534h-.801ZM53.274 30.78V9.234h7.329l4.952 8.164h.452V9.233h7.459V30.78h-7.363L61.213 22h-.5v8.78h-7.44ZM84.822 30.78h-9.425V9.234H84.5c3.703 0 6.372.835 8.007 2.507 1.634 1.671 2.452 4.344 2.452 8.02 0 3.68-.784 6.436-2.35 8.267-1.566 1.831-4.162 2.75-7.787 2.754Zm2.602-10.65c0-1.922-.198-3.208-.596-3.856-.397-.648-1.28-.97-2.65-.966h-1.322v9.39h1.322a3.261 3.261 0 0 0 2.472-.835c.516-.552.774-1.797.774-3.733ZM96.431 30.78V9.234h16.144v5.945h-8.712v1.836h6.753v5.883h-6.753v1.932h8.685v5.952H96.431ZM114.082 30.78V9.234h11.096l1.678 8.22 1.37-8.22h11.418V30.78h-7.452V19.685h-.357l-1.863 10.87h-6.246l-1.836-10.87h-.349V30.78h-7.459Z"
          />
        </g>
        <g opacity=".95" fill="#fff">
          <path
            opacity=".95"
            d="M183.87 29.986c-1.754.387-3.541.6-5.336.637a11.046 11.046 0 0 1-3.568-.472 5.305 5.305 0 0 1-3.535-3.535 20.2 20.2 0 0 1-.91-6.767c0-4.566.728-7.589 2.184-9.068 1.192-1.233 3.019-1.85 5.48-1.85 1.918.027 3.829.24 5.705.638l-.089 1.636a29.716 29.716 0 0 0-5.068-.575 8.405 8.405 0 0 0-2.959.411 4.721 4.721 0 0 0-1.699 1.09 4.483 4.483 0 0 0-.986 1.896 18.612 18.612 0 0 0-.603 5.158 36.427 36.427 0 0 0 .213 4.623c.12.965.395 1.904.815 2.781a3.64 3.64 0 0 0 1.794 1.788 7.978 7.978 0 0 0 3.274.548c1.753-.038 3.5-.23 5.219-.576l.069 1.637ZM188.842 24.13c0 3.197 1.752 4.795 5.254 4.795 1.815 0 3.185-.377 4.109-1.124.925-.746 1.37-1.972 1.37-3.67V9.232h1.884V24.13c0 2.288-.623 3.939-1.856 4.96-1.233 1.02-3.035 1.533-5.411 1.533-2.377 0-4.171-.513-5.37-1.534-1.199-1.02-1.808-2.671-1.808-4.959V9.233h1.849l-.021 14.897ZM216.24 30.322h-11.528V9.232h1.891v19.391h9.644l-.007 1.699ZM213.356 10.931V9.233h15.164v1.698h-6.616v19.39h-1.877v-19.39h-6.671ZM232.63 24.13c0 3.197 1.749 4.795 5.247 4.795 1.826 0 3.196-.375 4.109-1.124.913-.748 1.37-1.972 1.37-3.67V9.232h1.884V24.13c0 2.283-.617 3.936-1.85 4.96-1.233 1.022-3.039 1.533-5.418 1.533s-4.168-.511-5.369-1.534c-1.206-1.02-1.802-2.671-1.802-4.959V9.233h1.829V24.13ZM250.157 22.007v8.315h-1.883V9.232h7.74c2.205 0 3.849.5 4.931 1.5 1.082 1 1.623 2.585 1.623 4.754 0 3.38-1.356 5.434-4.068 6.165l4.336 8.678h-2.089l-4.144-8.322h-6.446Zm10.466-6.493c0-1.576-.374-2.738-1.123-3.487-.747-.753-1.911-1.123-3.486-1.123h-5.857v9.432h5.857c3.077 0 4.614-1.608 4.609-4.822ZM264.171 30.322l6.069-21.09h4.397l6.041 21.09h-1.849l-1.678-5.829h-9.425l-1.671 5.829h-1.884Zm7.432-19.452-3.425 11.959h8.493l-3.424-11.96h-1.644ZM294.959 30.322h-11.555V9.232h1.884v19.391h9.671v1.699Z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h310.384v40H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
